import {
  TopicState,
  TopicActions,
  TOGGLE_BOOKMARK,
  SAVE_ANSWER,
  EXTRACT_ANSWERS,
  FINISH_FETCH,
  TOGGLE_NAVIGATION,
  SAVE_ANSWERS,
} from './types'

const init: TopicState = {
  bookmarks: [],
  answeredQuestions: [],
  finishFetch: [],
  toggleNavigation: false,
}

export function topic(
  state: TopicState = init,
  action: TopicActions
): TopicState {
  switch (action.type) {
    case TOGGLE_BOOKMARK: {
      const { topicName, questionId } = action.payload
      let bookmarks = [...state.bookmarks]
      const index = bookmarks.findIndex((b) => b.topicName === topicName)
      if (index < 0) {
        bookmarks.push({
          topicName,
          questionIds: [questionId],
        })
      } else {
        if (!bookmarks[index].questionIds.includes(questionId)) {
          bookmarks[index].questionIds.push(questionId)
        } else {
          bookmarks[index].questionIds = bookmarks[index].questionIds.filter(
            (id) => id !== questionId
          )
        }
      }
      return {
        ...state,
        bookmarks,
      }
    }

    case SAVE_ANSWER: {
      const { questionId } = action.payload
      let answeredQuestions = [...state.answeredQuestions]
      const index = answeredQuestions.findIndex(
        (a) => a.questionId === questionId
      )

      if (index < 0) {
        answeredQuestions.push(action.payload)
      } else {
        answeredQuestions = answeredQuestions.map((answer) =>
          answer.questionId === questionId ? action.payload : answer
        )
      }

      if (questionId.includes('example')) {
        const answeredExampleQuestions = answeredQuestions.filter((answer) =>
          answer.questionId.includes('example')
        )

        localStorage.setItem(
          'Example Answers',
          JSON.stringify(answeredExampleQuestions)
        )
      }

      return {
        ...state,
        answeredQuestions,
      }
    }

    case SAVE_ANSWERS: {
      return {
        ...state,
        answeredQuestions: action.payload,
      }
    }

    case EXTRACT_ANSWERS:
      return {
        ...state,
        answeredQuestions: [...action.payload],
      }

    case FINISH_FETCH:
      return {
        ...state,
        finishFetch: [...state.finishFetch, action.payload],
      }

    case TOGGLE_NAVIGATION:
      return {
        ...state,
        toggleNavigation: !state.toggleNavigation,
      }

    default:
      return state
  }
}
