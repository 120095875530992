import React from 'react'
import './styles.scss'

type FormikRadioBtnTypes = {
  field: any
  id: string
  value: string
  htmlFor: string
}

const FormikRadioBtn = ({
  field: { name, onChange },
  id,
  value,
  htmlFor,
}: FormikRadioBtnTypes) => {
  return (
    <div className="radio__wrapper">
      <input
        className="option__input"
        type="radio"
        id={id}
        name={name}
        onChange={onChange}
        value={value}
      />
      <label htmlFor={htmlFor} className="option__label radio__label">
        <span className="option__button" />
        <p className="option__content">{value}</p>
      </label>
    </div>
  )
}

FormikRadioBtn.DisplayName = 'FormikRadioBtn'
export default FormikRadioBtn
