import {
  TOGGLE_BOOKMARK,
  SAVE_ANSWER,
  EXTRACT_ANSWERS,
  FINISH_FETCH,
  TOGGLE_NAVIGATION,
  TopicActions,
  AnsweredQuestion,
  SAVE_ANSWERS,
} from './types'

export function toggleBookmark(
  topicName: string,
  questionId: string
): TopicActions {
  return {
    type: TOGGLE_BOOKMARK,
    payload: {
      topicName,
      questionId,
    },
  }
}

export function saveAnswer(answer: AnsweredQuestion): TopicActions {
  return {
    type: SAVE_ANSWER,
    payload: answer,
  }
}

export function saveAnswers(answers: AnsweredQuestion[]): TopicActions {
  return {
    type: SAVE_ANSWERS,
    payload: answers,
  }
}

export function extractAnswers(answers: AnsweredQuestion[]): TopicActions {
  return {
    type: EXTRACT_ANSWERS,
    payload: answers,
  }
}

export function finishFetch(topicName: string): TopicActions {
  return {
    type: FINISH_FETCH,
    payload: topicName,
  }
}

export function toggleNavigation(): TopicActions {
  return {
    type: TOGGLE_NAVIGATION,
  }
}
