import React from 'react'
import { useSelector } from 'react-redux'

import Button from 'common/components/Button'
import { RootState } from 'redux/reducers'

type Props = {
  goHome: () => void
  takeTest: () => void
  handleRestartClick: () => void
  topicName: string
}

const TopicNavigation = ({
  goHome,
  takeTest,
  topicName,
  handleRestartClick,
}: Props) => {
  const isStarted = useSelector(
    (state: RootState) => state.topic.answeredQuestions
  )
  return (
    <>
      <Button variant="dark" text="HOME" size="large" onClick={goHome} />
      {topicName === 'Example' ? (
        <Button
          variant="outline"
          text="RESTART"
          size="large"
          onClick={handleRestartClick}
        />
      ) : null}
      <Button
        variant="yellow"
        text={isStarted ? 'CONTINUE' : 'START'}
        size="large"
        onClick={takeTest}
      />
    </>
  )
}

TopicNavigation.DisplayName = 'TopicNavigation'
export default TopicNavigation
