import { takeEvery, fork } from 'redux-saga/effects'

import { SET_SIDEBAR } from './types'

function* noticedSidebar() {
  yield takeEvery(SET_SIDEBAR, function* () {
    if (!localStorage.getItem('noticedSidebar')) {
      yield localStorage.setItem('noticedSidebar', 'true')
    }
  })
}

export default [noticedSidebar].map(fork)
