import { useSelector, useDispatch } from 'react-redux'

import { toggleBookmark } from '../redux/actions'
import { RootState } from 'redux/reducers'

export default function useBookmark(topicName: string, questionId: string) {
  const dispatch = useDispatch()
  const bookmarked = useSelector((state: RootState) => {
    const bookmarked = state.topic.bookmarks.some((tp) =>
      tp.questionIds.includes(questionId)
    )

    return bookmarked
  })

  const onClickHandler = () => {
    dispatch(toggleBookmark(topicName, questionId))
  }

  return {
    bookmarked,
    onClickHandler,
  }
}
