import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from 'redux/reducers'
import { setTestAnswers } from '../redux/actions'
import { Answer } from '../redux/types'
import { AnsweredQuestion } from 'pages/topic/redux/types'

export default function useAnswer(questionId: string) {
  const dispatch = useDispatch()
  const [currentAnswer, setCurrentAnswer] = useState<Answer | null>(null)

  const { initialAnswer, answeredQuestions, navigationReady } = useSelector(
    (state: RootState) => {
      let initialAnswer: Answer = {
        student: state.auth.user!.id!,
        test: state.test.testId!,
        questionId: questionId,
        optionId: null,
        answerText: null,
      }
      const { answeredQuestions, toggleNavigation } = state.topic

      //There may be multiple answeredQuestion objects for checkbox question
      const answeredQuestion = answeredQuestions.filter(
        (e) => e.questionId === questionId
      )
      if (answeredQuestion.length > 0) {
        let optionIdArr: string[] = []
        answeredQuestion.map((a) => {
          if (a.optionId && typeof a.optionId === 'string') {
            optionIdArr.push(a.optionId as string) //optionId first returned from server is in string type
          }
          if (a.optionId && typeof a.optionId === 'object') {
            optionIdArr = [...a.optionId] //as optionId is saved in array after users choose their answers, so this is for when they change their answers
          }
          return optionIdArr
        })
        if (optionIdArr.length > 0) {
          initialAnswer.optionId = optionIdArr
        }
        initialAnswer.answerText = answeredQuestion[0].answerText
      }
      if (questionId.includes('example')) {
        if (localStorage.getItem('Example Answers')) {
          const exampleAnswers = JSON.parse(
            localStorage.getItem('Example Answers')!
          ) as AnsweredQuestion[]
          const answeredExampleQuestion = exampleAnswers.find(
            (e) => e.questionId === questionId
          )
          if (answeredExampleQuestion) {
            initialAnswer.optionId = answeredExampleQuestion!.optionId
            initialAnswer.answerText = answeredExampleQuestion!.answerText
          }
        }
      }

      const currentQuestion = state.test.test?.topics.find((topic) =>
        topic.questions.some((question) => question.questionId === questionId)
      )

      initialAnswer.testQuestionId = currentQuestion?.questions.find(
        (question) => question.questionId === questionId
      )?.testQuestionId

      return {
        navigationReady: toggleNavigation,
        initialAnswer,
        answeredQuestions,
      }
    }
  )

  useEffect(() => {
    if (currentAnswer !== null) {
      dispatch(setTestAnswers(currentAnswer))
    }
  }, [currentAnswer, dispatch])

  useEffect(() => {
    if (currentAnswer === null || currentAnswer.questionId !== questionId) {
      setCurrentAnswer(initialAnswer)
    }
  }, [currentAnswer, dispatch, questionId, initialAnswer])

  useEffect(() => {
    const MCQquestions = answeredQuestions.filter(
      (q) => q.questionId === currentAnswer?.questionId && q.optionId !== null
    )
    const Textquestions = answeredQuestions.filter(
      (q) => q.questionId === currentAnswer?.questionId && q.answerText !== null
    )
    //Update answers when switching between answers from different topics via sidebar
    if (
      (currentAnswer?.optionId === null && MCQquestions.length > 0) ||
      (currentAnswer?.answerText === null && Textquestions.length > 0)
    ) {
      setCurrentAnswer(initialAnswer)
    }
  }, [currentAnswer, dispatch, questionId, initialAnswer, answeredQuestions])

  return {
    currentAnswer,
    setCurrentAnswer,
    answeredQuestions,
    navigationReady,
    initialAnswer,
  }
}
