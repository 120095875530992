export const SEND_FEEDBACK = 'SEND_FEEDBACK'

export interface TopicFeedback {
  topicName: string
  feedback: string
}

export interface Feedback {
  feedback: TopicFeedback[]
}

export interface SendFeedbackAction {
  type: typeof SEND_FEEDBACK
  payload: Feedback
}

export type FeedbackActions = SendFeedbackAction
