import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'
import TopicCard from 'pages/topic/components/TopicCard'
import Button from 'common/components/Button'
import { RootState } from 'redux/reducers'
import useTest from 'pages/test/hooks/useTest'
import exampleTest from 'pages/topic/redux/exampleTest'
import ProductTour from 'common/components/ProductTour'
import { homeSteps } from 'common/components/TourSteps'
import { setSidebar, showNotification } from 'common/redux/actions'
import useProductTour from 'common/hooks/useProductTour'
import Heading from 'common/components/Heading'
import TourIcon from 'common/components/TourIcon'
import './styles.scss'

const HomePage = () => {
  const { test } = useTest()
  const isTourOpen = useProductTour()
  const dispatch = useDispatch()
  const { topics, totalTime } = useSelector((state: RootState) => {
    return {
      topics: state.test.test?.topics,
      totalTime: state.test.test?.totalTime,
    }
  })

  const totalTimeString = useMemo(() => {
    let temp = ''
    const formattedTotalTime = moment
      .duration(totalTime, 'seconds')
      .format('hh:mm:ss', { trim: false })
      .split(':')

    if (formattedTotalTime[0] !== '00') {
      temp += `${formattedTotalTime[0]}h `
    }
    if (formattedTotalTime[1] !== '00') {
      temp += `${formattedTotalTime[1]}m `
    }
    if (formattedTotalTime[2] !== '00') {
      temp += `${formattedTotalTime[2]}s`
    }

    return temp
  }, [totalTime])

  const totalNumberOfQuestions =
    test && test?.topics
      ? test?.topics.reduce((acc, cur) => (acc += cur.questions.length), 0)
      : 0

  const heading = 'Welcome to Integrify Test!'

  const warningMessage = 'Are you sure you want to finish the test?'

  const handleButton = () => {
    dispatch(showNotification(warningMessage, 'warningFinish'))
    dispatch(setSidebar(false))
  }

  if (!test) {
    return <>Loading...</>
  }

  return (
    <>
      <div className="homepage">
        <ProductTour steps={homeSteps} isOpen={isTourOpen} />
        <div className="homepage__greeting">
          <Heading
            mainHeading={heading}
            variant="primary"
            subHeading="Wish you all the luck!"
          />
        </div>
        <div className="homepage__insight">
          <ul>
            <li>
              Topics: <span>{topics?.length || 0}</span>{' '}
            </li>
            <li>
              Total Questions: <span>{totalNumberOfQuestions}</span>
            </li>
            <li>
              Total Time: <span>{totalTimeString}</span>
            </li>
          </ul>
        </div>

        <div className="homepage__example">
          <TopicCard topic={exampleTest} isExample />
        </div>
        <div className="homepage__cards">
          {topics?.map((topic) => {
            return <TopicCard key={topic.name} topic={topic} />
          })}
        </div>
        <div className="homepage__btn">
          <Button
            variant="yellow"
            text="FINISH"
            size="regular"
            onClick={handleButton}
          />
        </div>
        <TourIcon />
      </div>
    </>
  )
}

HomePage.DisplayName = 'HomePage'
export default HomePage
