import { takeEvery, call, put, fork } from 'redux-saga/effects'

import {
  LOG_IN_WITH_GOOGLE,
  LOG_IN_WITH_ACCOUNT,
  AuthenticatedUser,
  LOG_OUT,
  LogInWithGoogleAction,
  LogInWithAccountAction,
} from './types'
import API from '../services/api'
import { loginSuccess } from './actions'
import { showNotification } from 'common/redux/actions'

function* loginWithGoogle() {
  yield takeEvery(
    LOG_IN_WITH_GOOGLE,
    function* (action: LogInWithGoogleAction) {
      const { idToken, history } = action.payload
      try {
        const response = yield call(API.loginWithGoogle, idToken)
        const user = response as AuthenticatedUser

        yield put(loginSuccess(user))

        history.push('/')
      } catch (error) {
        yield put(showNotification(error.data.message, 'error'))
      }
    }
  )
}

function* loginWithAccount() {
  yield takeEvery(
    LOG_IN_WITH_ACCOUNT,
    function* (action: LogInWithAccountAction) {
      const { email, password, history } = action.payload
      try {
        const response = yield call(API.loginWithAccount, email, password)
        const user = response as AuthenticatedUser

        yield put(loginSuccess(user))

        history.push('/')
      } catch (error) {
        yield put(showNotification(error.data.message, 'error'))
      }
    }
  )
}

function* logout() {
  yield takeEvery(LOG_OUT, function* () {
    try {
      yield call(API.logout)

      yield localStorage.clear()
    } catch (error) {
      yield put(showNotification(error.data.message, 'error'))
    }
  })
}

export default [loginWithGoogle, loginWithAccount, logout].map(fork)
