import React from 'react'
import { Field } from 'formik'

import Heading from 'common/components/Heading'
import './styles.scss'
import FormikRadioBtn from 'pages/test/components/FormikRadioBtn'

type FeedbackQuestionsProps = {
  topicName: string
  index: number
}

const FeedbackQuestions = ({ topicName, index }: FeedbackQuestionsProps) => {
  return (
    <div className="form-container">
      <h1 className="form-container__title">{topicName}</h1>
      <Heading
        mainHeading="How difficult are the questions?"
        variant="tertiary"
      />
      <div
        className="form-container__wrapper"
        role="group"
        aria-labelledby="difficulty-group"
      >
        <Field
          id={`topics[${index}].difficulty.easy`}
          htmlFor={`topics[${index}].difficulty.easy`}
          className="form-container__option radio"
          component={FormikRadioBtn}
          name={`topics[${index}].difficulty`}
          value="Easy"
        />
        <Field
          id={`topics[${index}].difficulty.ok`}
          htmlFor={`topics[${index}].difficulty.ok`}
          className="option__button radio"
          component={FormikRadioBtn}
          name={`topics[${index}].difficulty`}
          value="OK"
        />
        <Field
          id={`topics[${index}].difficulty.difficult`}
          htmlFor={`topics[${index}].difficulty.difficult`}
          className="option__button radio"
          component={FormikRadioBtn}
          name={`topics[${index}].difficulty`}
          value="Difficult"
        />
      </div>

      <Heading
        mainHeading="How relevant are the questions?"
        variant="tertiary"
      />
      <div
        className="form-container__wrapper"
        role="group"
        aria-labelledby="difficulty-group"
      >
        <Field
          id={`topics[${index}].relevancy.relevant`}
          htmlFor={`topics[${index}].relevancy.relevant`}
          className="option__button radio"
          component={FormikRadioBtn}
          name={`topics[${index}].relevancy`}
          value="Relevant"
        />
        <Field
          id={`topics[${index}].relevancy.irrelevant`}
          htmlFor={`topics[${index}].relevancy.irrelevant`}
          className="option__button radio"
          component={FormikRadioBtn}
          name={`topics[${index}].relevancy`}
          value="Irrelevant"
        />
      </div>
    </div>
  )
}

FeedbackQuestions.DisplayName = 'FeedbackQuestions'
export default FeedbackQuestions
