import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'redux/reducers'
import { fetchTest } from '../redux/actions'
import { logout } from 'pages/auth/redux/actions'
import { showNotification } from 'common/redux/actions'

export default function useTest() {
  const dispatch = useDispatch()
  const { test, testId, startAt } = useSelector((state: RootState) => ({
    test: state.test.test,
    testId: state.test.testId,
    startAt: state.test.test?.startAt,
  }))

  useEffect(() => {
    if (testId) {
      dispatch(fetchTest(testId))
    } else {
      dispatch(
        showNotification(
          "No valid testId found !!! make sure you're following a valid test link",
          'error'
        )
      )
      dispatch(logout())
    }
  }, [dispatch, testId])

  return {
    test,
    startAt,
  }
}
