import React, { FC } from 'react'
import './styles.scss'
import { CircularProgressbar } from 'react-circular-progressbar'

export type VariantType = 'small' | 'big'

interface ProgressCircleProps {
  variant: VariantType
  percentage: number
  text: string
  strokeWidth: number
}

const ProgressCircle: FC<ProgressCircleProps> = ({
  variant,
  percentage,
  text,
}) => {
  return (
    <div className={variant}>
      <CircularProgressbar
        value={percentage}
        text={text}
        strokeWidth={6}
        styles={{
          trail: {
            stroke: percentage > 0 ? 'none' : '',
          },
        }}
      />
    </div>
  )
}

export default ProgressCircle
