import { Topic } from 'pages/topic/redux/types'

export const SET_ANSWER = 'SET_ANSWER'
export const SEND_ANSWER = 'SEND_ANSWER'
export const FETCH_QUESTION = 'FETCH_QUESTION'
export const FETCH_QUESTION_SUCCESS = 'FETCH_QUESTION_SUCCESS'
export const RESET_TEST = 'RESET_TEST'
export const SAVE_TEST_ID = 'SAVE_TEST_ID'
export const FINISH_TEST = 'FINISH_TEST'
export const FINISH_TEST_SUCCESS = 'FINISH_TEST_SUCCESS'
export const FETCH_TEST = 'FETCH_TEST'
export const FETCH_TEST_SUCCESS = 'FETCH_TEST_SUCCESS'
export const START_TEST = 'START_TEST'
export const START_TEST_SUCCESS = 'START_TEST_SUCCESS'
export const RESET_ANSWER = 'RESET_ANSWER'

export type Test = {
  id: string
  topics: Topic[]
  timeLeft: number
  startAt: number
  isFinished: boolean
  totalTime: number
}

export enum Difficulty {
  EASY = 'easy',
  MEDIUM = 'medium',
  HARD = 'hard',
}

export type Choice = {
  id: string
  content: string
  image: string
}

export interface TestState {
  testId?: string
  test?: Test
  questions: Question[]
  currentAnswer?: Answer
  currentQuestion?: Question
}

export type Question = {
  id: string
  questionType: string
  content: string
  image: string
  difficulty: string
  options: Choice[]
}

export type Answer = {
  student: string
  test: string
  testQuestionId?: string
  questionId: string
  optionId: string | string[] | null
  answerText: string | null
}

export type AnswerPayload = {
  student: string
  test: string
  questionId: string
  testQuestionId: string
  optionIds: string[] | null
  answerText: string | null
}

export type FetchQuestionAction = {
  type: typeof FETCH_QUESTION
  payload: {
    questionId: string
  }
}

export type FetchQuestionSuccessAction = {
  type: typeof FETCH_QUESTION_SUCCESS
  payload: Question
}

export type SetAnswerAction = {
  type: typeof SET_ANSWER
  payload: Answer
}

export type SendAnswerAction = {
  type: typeof SEND_ANSWER
  payload: boolean
}

export type ResetTestStateAction = {
  type: typeof RESET_TEST
  payload: undefined
}

export type FinishTestAction = {
  type: typeof FINISH_TEST
}

export type FinishTestSuccessAction = {
  type: typeof FINISH_TEST_SUCCESS
}

export type SaveTestIdAction = {
  type: typeof SAVE_TEST_ID
  payload: {
    testId: string
  }
}

export type FetchTestAction = {
  type: typeof FETCH_TEST
  payload: {
    testId: string
  }
}

export type FetchTestSuccessAction = {
  type: typeof FETCH_TEST_SUCCESS
  payload: Test
}

export type StartTestAction = {
  type: typeof START_TEST
}

export type StartTestSuccessAction = {
  type: typeof START_TEST_SUCCESS
}

export type ResetCurrentAnswerAction = {
  type: typeof RESET_ANSWER
}

export type TestActions =
  | SetAnswerAction
  | FetchQuestionAction
  | FetchQuestionSuccessAction
  | FetchTestAction
  | FetchTestSuccessAction
  | ResetTestStateAction
  | FinishTestAction
  | FinishTestSuccessAction
  | SaveTestIdAction
  | StartTestAction
  | SendAnswerAction
  | StartTestSuccessAction
  | ResetCurrentAnswerAction
