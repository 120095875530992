import React from 'react'

import './styles.scss'

type DescriptionType = 'short' | 'fullLeft' | 'fullCenter'
type DescriptionVariant = 'dark' | 'light'

interface DescriptionProps {
  content: string
  type: DescriptionType
  variant: DescriptionVariant
}

const Description = ({ content, type, variant }: DescriptionProps) => {
  return (
    <p className={`description description--${type} description--${variant}`}>
      {type === 'fullLeft' || type === 'fullCenter'
        ? content
        : `${content.slice(0, 30)}...`}
    </p>
  )
}

Description.displayName = 'Description'
export default Description
