import {
  SET_ANSWER,
  Answer,
  SetAnswerAction,
  FETCH_QUESTION,
  FETCH_QUESTION_SUCCESS,
  RESET_TEST,
  ResetTestStateAction,
  TestActions,
  FINISH_TEST,
  SAVE_TEST_ID,
  Question,
  FETCH_TEST,
  FETCH_TEST_SUCCESS,
  Test,
  FINISH_TEST_SUCCESS,
  START_TEST,
  SEND_ANSWER,
  START_TEST_SUCCESS,
  RESET_ANSWER,
} from './types'

export function fetchQuestion(questionId: string): TestActions {
  return {
    type: FETCH_QUESTION,
    payload: {
      questionId,
    },
  }
}

export function fetchQuestionSuccess(question: Question): TestActions {
  return {
    type: FETCH_QUESTION_SUCCESS,
    payload: question,
  }
}

export function fetchTest(testId: string): TestActions {
  return {
    type: FETCH_TEST,
    payload: {
      testId,
    },
  }
}

export function fetchTestSuccess(test: Test): TestActions {
  return {
    type: FETCH_TEST_SUCCESS,
    payload: test,
  }
}

export function setTestAnswers(answer: Answer): SetAnswerAction {
  return {
    type: SET_ANSWER,
    payload: answer,
  }
}
export function resetTest(): ResetTestStateAction {
  return {
    type: RESET_TEST,
    payload: undefined,
  }
}

export function finishTest(): TestActions {
  return {
    type: FINISH_TEST,
  }
}

export function finishTestSuccess(): TestActions {
  return {
    type: FINISH_TEST_SUCCESS,
  }
}

export function startTest(): TestActions {
  return {
    type: START_TEST,
  }
}

export function startTestSuccess(): TestActions {
  return {
    type: START_TEST_SUCCESS,
  }
}

export function saveTestId(testId: string): TestActions {
  return {
    type: SAVE_TEST_ID,
    payload: {
      testId,
    },
  }
}

export function sendAnswer(skipSubmit: boolean): TestActions {
  return {
    type: SEND_ANSWER,
    payload: skipSubmit,
  }
}

export function resetCurrentAnswer(): TestActions {
  return {
    type: RESET_ANSWER,
  }
}
