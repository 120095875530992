import React from 'react'

import './styles.scss'

type QuestionImageProps = {
  image: string
}

export default function index({ image }: QuestionImageProps) {
  return (
    <>
      <div className="test-question__image">
        <img src={image} alt="Question" />
      </div>
    </>
  )
}
