import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import TopicDescription from './components/TopicDescription'
import TopicProgress from './components/TopicProgress'
import TopicNavigation from './components/TopicNavigation'
import useTopic from 'pages/topic/hooks/useTopic'
import { startTest } from 'pages/test/redux/actions'
import exampleTopic from 'pages/topic/redux/exampleTest'
import useTest from 'pages/test/hooks/useTest'
import ProductTour from 'common/components/ProductTour'
import { topicSteps } from 'common/components/TourSteps'
import useProductTour from 'common/hooks/useProductTour'
import TourIcon from 'common/components/TourIcon'
import './styles.scss'
import { toggleBookmark } from './redux/actions'
import { RootState } from 'redux/reducers'
import { TestQuestionIdObj } from './redux/types'
import { Difficulty } from '../test/redux/types'

type TopicConfig = {
  difficulty: Difficulty
  numberOfQuestions: number
}[]

const TopicPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  let { topicName } = useParams<{ topicName: string }>()
  topicName = decodeURIComponent(topicName)
  const { startAt } = useTest()
  const isTourOpen = useProductTour()
  const exampleBookmarkList = useSelector((state: RootState) => {
    const exampleTopic = state.topic.bookmarks.find(
      (tp) => tp.topicName === topicName
    )
    return exampleTopic?.questionIds
  })
  let topic = useTopic(topicName)
  if (topicName === 'Example') {
    topic = exampleTopic
  }

  const topicConfig: TopicConfig = []

  Object.values(Difficulty).forEach((difficulty) => {
    const numberOfQuestionMatched = topic!.questions.filter(
      (q) => q.difficulty === difficulty
    ).length

    if (numberOfQuestionMatched > 0)
      topicConfig.push({
        difficulty,
        numberOfQuestions: numberOfQuestionMatched,
      })
  })

  const questionId = (topic?.questions[0] as TestQuestionIdObj)?.questionId

  const goHome = () => {
    history.push('/')
  }

  const takeTest = () => {
    if (topic && topicName !== 'Example' && startAt === null) {
      dispatch(startTest())
    }
    history.push(`${location.pathname}/${questionId}`)
  }

  const handleRestartClick = () => {
    localStorage.clear()
    if (exampleBookmarkList) {
      exampleBookmarkList.map((questionId) =>
        dispatch(toggleBookmark(topicName, questionId))
      )
    }
    history.push(`${location.pathname}/${questionId}`)
  }

  if (!topic) {
    return <div>Invalid topic!</div>
  }

  return (
    <div className="topic-page">
      <ProductTour steps={topicSteps} isOpen={isTourOpen} />

      {/* <TopicPanel /> */}
      <div className="topic-content">
        <div className="topic-content__description">
          <TopicDescription topic={topic} />
          <div>
            <br />
            <ul className="topic-content__description__list">
              {topicConfig.map(({ numberOfQuestions, difficulty }, index) => (
                <li key={index}>
                  <span style={{ textTransform: 'capitalize' }}>
                    {difficulty}
                  </span>
                  : <span>{numberOfQuestions} Questions </span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="topic-content__progress">
          <TopicProgress topic={topic} size="big" />
        </div>
      </div>
      <div className="topic-navigation">
        <TopicNavigation
          goHome={goHome}
          takeTest={takeTest}
          handleRestartClick={handleRestartClick}
          topicName={topicName}
        />
      </div>
      {topicName === 'Example' && <TourIcon />}
    </div>
  )
}

TopicPage.DisplayName = 'TopicPage'
export default TopicPage
