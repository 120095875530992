import React, { FC } from 'react'

import './styles.scss'

export type VariantType = 'square' | 'rectangle' | 'circle'
export type Size = 'xs' | 'sm' | 'md' | 'lg'

interface ImageProps {
  id?: string
  src?: string
  size?: Size
  variant?: VariantType
  alt?: string
}

const Image: FC<ImageProps> = ({ id, src, size, variant, alt }) => {
  return (
    <div className={size}>
      <img
        id={id}
        src={src}
        alt={alt}
        className={`image--${size}--${variant}`}
      />
    </div>
  )
}

export default Image
