import {
  CommonState,
  CommonActions,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  TOGGLE_TOUR,
  SET_SIDEBAR,
} from './types'

const defaultState: CommonState = {
  notification: null,
  isTourOpen: true,
  isSidebarOpen: false,
}

export function common(
  state: CommonState = defaultState,
  action: CommonActions
): CommonState {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      }

    case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: null,
      }

    case TOGGLE_TOUR:
      return {
        ...state,
        isTourOpen: action.payload,
      }

    case SET_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: action.payload,
      }

    default:
      return state
  }
}
