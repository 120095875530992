import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import 'moment-duration-format'

import './styles.scss'
import { RootState } from 'redux/reducers'
import { finishTest, sendAnswer } from 'pages/test/redux/actions'
import { showNotification } from 'common/redux/actions'

const Timer = () => {
  const { initialTime, startAt, isFinished } = useSelector(
    (state: RootState) => {
      return {
        initialTime: state.test.test?.timeLeft! * 1000 || 0,
        startAt: state.test.test?.startAt,
        isFinished: state.test.test?.isFinished,
      }
    }
  )
  const [duration, setDuration] = useState(initialTime)
  const displayTime = () => {
    return moment.duration(duration).format('hh:mm:ss', { trim: false })
  }
  const delay = 1000
  const display = displayTime().split(':')
  const dispatch = useDispatch()
  const history = useHistory()
  const timeUpMessage =
    "Time's up !!! You are not allowed to continue the test anymore"

  useEffect(() => {
    if (isFinished) {
      dispatch(showNotification(timeUpMessage, 'error'))
      history.push('/feedback')
    }
    const interval = setInterval(() => {
      if (startAt && duration > 0) {
        setDuration((value) => {
          localStorage.setItem('duration', (value - delay).toString())
          return value - delay
        })
      } else if (duration <= 0 && startAt && !isFinished) {
        dispatch(sendAnswer(false))
        dispatch(finishTest())
      }
    }, delay)
    return () => clearInterval(interval)
  }, [dispatch, duration, history, initialTime, isFinished, startAt])

  useEffect(() => {
    const savedDuration = +localStorage.getItem('duration')!
    if (initialTime <= savedDuration || savedDuration === 0) {
      localStorage.setItem('duration', initialTime.toString())
      setDuration(initialTime)
    } else {
      setDuration(+savedDuration)
    }
  }, [initialTime])

  return (
    <>
      <div className="timer">
        <div className="timer--counter">
          <label>
            <p>{display[0]}</p>HOURS
          </label>
        </div>
        <span className="colon">:</span>
        <div className="timer--counter">
          <label>
            <p>{display[1]}</p>MINUTES
          </label>
        </div>
        <span className="colon">:</span>
        <div className="timer--counter">
          <label>
            <p>{display[2]}</p>SECONDS
          </label>
        </div>
      </div>
    </>
  )
}

Timer.DisplayName = 'Timer'

export default Timer
