import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import Routes from './Routes'
import Header from 'common/components/Header'
import Footer from 'common/components/Footer'
import useCurrentUser from './pages/auth/hooks/useCurrentUser'
import useNotification from 'common/hooks/useNotification'
import Alert from 'common/components/Alert'
import { hideNotification } from 'common/redux/actions'
import { finishTest } from 'pages/test/redux/actions'
import './App.scss'
import SideBar from 'pages/test/components/SideBar'
import { RootState } from 'redux/reducers'

function App() {
  const currentUser = useCurrentUser()
  const history = useHistory()
  const dispatch = useDispatch()
  const notification = useNotification()
  const isSidebarOpen = useSelector(
    (state: RootState) => state.common.isSidebarOpen
  )

  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      autoSessionTracking: true,
      integrations: [new Integrations.BrowserTracing()],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    })
  }

  const handleUserTab = (e: { keyCode: number }) => {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing')
      window.removeEventListener('keydown', handleUserTab)
    }
  }

  window.addEventListener('keydown', handleUserTab)
  useEffect(() => {
    if (!currentUser?.id) {
      history.push('/login')
    }
  }, [history, currentUser])

  const hideNoti = () => {
    dispatch(hideNotification())
  }

  const testFinish = () => {
    dispatch(finishTest())
    dispatch(hideNotification())
    history.push('/feedback')
  }

  useEffect(() => {
    // scroll to top on page transition
    const unListen = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unListen()
    }
  }, [history])

  return (
    <>
      <Header />
      <div className={`side-bar side-bar--${isSidebarOpen ? 'show' : ''}`}>
        <SideBar />
      </div>
      <Alert
        toggleAlert={notification !== null ? true : false}
        type={notification?.variant}
        text={notification?.message}
        onDeclineClick={hideNoti}
        onApproveClick={testFinish}
      />
      <Routes />
      <Footer />
    </>
  )
}

export default App
