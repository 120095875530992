import React from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Choice } from '../../redux/types'
import QuestionOption from '../QuestionOption'
import useAnswer from 'pages/test/hooks/useAnswer'
import useBookmark from 'pages/topic/hooks/useBookmark'
import { sendAnswer } from 'pages/test/redux/actions'

type QuestionOptionProps = {
  options: Choice[]
  type: string
  questionId: string
}
const QuestionOptions = ({
  options,
  type,
  questionId,
}: QuestionOptionProps) => {
  const questionType = type === 'checkbox' ? 'checkbox' : 'radio'
  const { currentAnswer, setCurrentAnswer } = useAnswer(questionId)
  const { topicName } = useParams()
  const { bookmarked } = useBookmark(topicName, questionId)
  const dispatch = useDispatch()
  let answer = currentAnswer?.optionId

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target
    if (questionType === 'checkbox') {
      if (answer === null || !answer) answer = []
      if (answer.includes(id)) {
        answer = (answer as string[]).filter((choice: any) => choice !== id)
      } else (answer as string[]).push(id)
      setCurrentAnswer((value) => {
        return {
          ...value!,
          optionId: answer as string[],
        }
      })
    } else {
      answer = [id]
      setCurrentAnswer((value) => {
        return {
          ...value!,
          optionId: answer as string[],
        }
      })
    }
    dispatch(sendAnswer(bookmarked))
  }

  const checked = (id: string): boolean => {
    if (answer === null || !answer) answer = []
    return answer.includes(id)
  }

  const listItems = options.map((option) => (
    <QuestionOption
      key={option.id}
      questionType={questionType}
      option={option}
      checked={checked}
      onChangeHandler={onChangeHandler}
    />
  ))

  return <div className="test-question__options">{listItems}</div>
}

QuestionOptions.DisplayName = 'QuestionOptions'
export default QuestionOptions
