import { SEND_FEEDBACK, SendFeedbackAction } from 'pages/feedback/redux/types'
import {
  SAVE_TEST_ID,
  SET_ANSWER,
  FETCH_QUESTION_SUCCESS,
  FETCH_TEST_SUCCESS,
  FINISH_TEST_SUCCESS,
  START_TEST_SUCCESS,
  TestState,
  TestActions,
  RESET_ANSWER,
} from './types'

const initState: TestState = {
  questions: [],
}

export function test(
  state: TestState = initState,
  action: TestActions | SendFeedbackAction
): TestState {
  switch (action.type) {
    case SET_ANSWER:
      return {
        ...state,
        currentAnswer: action.payload,
      }

    case FETCH_QUESTION_SUCCESS: {
      const question = action.payload
      const questionIndex = state.questions?.findIndex(
        (q) => q.id === question.id
      )

      if (questionIndex && questionIndex >= 0) {
        state.questions.splice(questionIndex, 0, question)
      } else {
        state.questions.push(question)
      }
      return {
        ...state,
        questions: [...state.questions],
        currentQuestion: action.payload,
      }
    }

    case FETCH_TEST_SUCCESS:
      return {
        ...state,
        test: action.payload,
      }

    case SAVE_TEST_ID:
      return {
        ...state,
        testId: action.payload.testId,
      }

    case FINISH_TEST_SUCCESS: {
      let { test } = state
      test = {
        ...test!,
        isFinished: true,
      }
      return {
        ...state,
        test,
      }
    }

    case START_TEST_SUCCESS: {
      let { test } = state
      test = {
        ...test!,
        startAt: new Date().getUTCDate(),
      }
      return {
        ...state,
        test,
      }
    }

    case SEND_FEEDBACK: {
      return state
    }

    case RESET_ANSWER: {
      return {
        ...state,
        currentAnswer: undefined,
      }
    }

    default:
      return state
  }
}
