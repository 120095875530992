import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import Button from 'common/components/Button'
import Question from './components/Question'
import useBookmark from 'pages/topic/hooks/useBookmark'
import useAnswer from './hooks/useAnswer'
import useQuestion from './hooks/useQuestion'
import { testSteps } from 'common/components/TourSteps'
import ProductTour from 'common/components/ProductTour'
import useProductTour from 'common/hooks/useProductTour'
import { toggleNavigation } from 'pages/topic/redux/actions'
import './styles.scss'
import { RootState } from 'redux/reducers'
import { resetCurrentAnswer } from './redux/actions'
import exampleTopic from 'pages/topic/redux/exampleTest'
import { ReactComponent as Bookmark } from 'assets/images/bookmark.svg'
import TourIcon from 'common/components/TourIcon'
import { setSidebar, showNotification } from 'common/redux/actions'
import useTopic from 'pages/topic/hooks/useTopic'

export default function QuestionPage() {
  let { questionId, topicName } =
    useParams<{ questionId: string; topicName: string }>()
  topicName = decodeURIComponent(topicName)
  const dispatch = useDispatch()
  const history = useHistory()

  let topic = useTopic(topicName)
  if (topicName === 'Example') {
    topic = exampleTopic
  }

  const { bookmarked, onClickHandler } = useBookmark(topic!.name, questionId)

  const { answeredQuestions, navigationReady } = useAnswer(questionId)

  const { questionNumber, questions, question, fetchFinished } = useQuestion(
    topic!.name,
    questionId
  )
  const isTourOpen = useProductTour()
  const topicsList = useSelector((state: RootState) => state.test.test?.topics)
  const currentAnswer = useSelector(
    (state: RootState) => state.test.currentAnswer
  )

  const isSidebarOpen = useSelector(
    (state: RootState) => state.common.isSidebarOpen
  )

  const totalQuestionNumber =
    topicName === 'Example'
      ? exampleTopic.questions.length
      : topicsList?.filter((topic) => topic.name === topicName)[0].questions
          .length

  useEffect(() => {
    if (!localStorage.getItem('noticedSidebar')) {
      dispatch(setSidebar(true))
    }

    // Clean up: When QuestionPage unmount -> Set sidebar false
    return () => {
      dispatch(setSidebar(false))
    }
  }, [dispatch])

  useEffect(() => {
    if (navigationReady) {
      if (topicsList && topicName !== 'Example') {
        //Check if the current question is the last one of the current topic
        if (questionNumber === questions.length) {
          const topicIndex = topicsList.indexOf(
            topicsList.filter((topic) => topic.name === topicName)[0]
          )
          //Move to the next topic
          if (topicIndex < topicsList.length - 1) {
            history.push(
              `/topics/${encodeURIComponent(topicsList[topicIndex + 1].name)}`
            )
          } else {
            history.push('/') //Go back to homepage if it is the last topic
          }
        } else {
          //If not the last question, go to the next question of the same topic
          history.push(
            `/topics/${encodeURIComponent(topicName)}/${
              questions[questionNumber].questionId
            }`
          )
        }
      }
      //This is the case when user is at the Example topic
      if (topicName === 'Example') {
        if (questionNumber === questions.length) {
          history.push('/')
        } else {
          history.push(
            `/topics/${encodeURIComponent(topicName)}/${
              questions[questionNumber].questionId
            }`
          )
        }
      }
      dispatch(toggleNavigation())
    }
  }, [
    answeredQuestions,
    dispatch,
    fetchFinished,
    history,
    navigationReady,
    questionNumber,
    questions,
    topicName,
    topicsList,
  ])

  const handleHomeClick = () => {
    if (
      ((currentAnswer?.optionId !== null && currentAnswer?.optionId?.length) ??
        -1) > 0 ||
      (currentAnswer?.answerText !== null &&
        (currentAnswer?.answerText ?? '').trim().length > 0) ||
      bookmarked
    ) {
      history.push('/')
      dispatch(resetCurrentAnswer())
    } else {
      dispatch(
        showNotification(
          'You can not leave answer blank, unless you bookmark it!',
          'error'
        )
      )
    }
  }

  const handleNext = () => {
    const optionLength = currentAnswer?.optionId?.length ?? 0
    const textLength = currentAnswer?.answerText?.trim().length ?? 0
    if (
      (currentAnswer?.optionId !== null && optionLength > 0) ||
      (currentAnswer?.answerText !== null && textLength > 0) ||
      bookmarked
    ) {
      dispatch(toggleNavigation())
    } else {
      dispatch(
        showNotification(
          'You can not leave answer blank, unless you bookmark it!',
          'error'
        )
      )
    }
  }
  const handleClipboardEvent = (e: React.ClipboardEvent) => {
    e.preventDefault()
  }
  return (
    <div
      className="test--container"
      onCopy={handleClipboardEvent}
      onPaste={handleClipboardEvent}
      onCut={handleClipboardEvent}
    >
      <ProductTour steps={testSteps} isOpen={isTourOpen} />

      <div className={`test test--${isSidebarOpen ? 'show' : ''}`}>
        <div className="test__topic-name">{topicName}</div>
        {question && (
          <div className="test__body">
            <div className="test__total-questions">{`Question ${questionNumber} of ${totalQuestionNumber}`}</div>
            <Question question={question} questionNumber={questionNumber} />
          </div>
        )}
      </div>
      <div className="test__footer">
        <Button variant="dark" text="Home" onClick={() => handleHomeClick()} />
        <div className="rightButtons-container">
          <button className="btn-bookmark" onClick={onClickHandler}>
            <Bookmark className={`${bookmarked ? 'bookmarked' : 'bookmark'}`} />
            <span className="bookmark-tooltip">Bookmark for later review</span>
          </button>
          <Button
            variant="yellow"
            text="Next"
            onClick={() => {
              handleNext()
            }}
          />
        </div>
      </div>
      {topicName === 'Example' && <TourIcon />}
    </div>
  )
}
