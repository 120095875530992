import React from 'react'
import { useDispatch } from 'react-redux'
import Tour, { ReactourStep } from 'reactour'
import { useParams } from 'react-router-dom'

import './styles.scss'
import Button from 'common/components/Button'
import { toggleTour } from 'common/redux/actions'

type Props = {
  isOpen: boolean
  steps: ReactourStep[]
}

const ProductTour = ({ steps, isOpen }: Props) => {
  const { questionId } = useParams<any>()
  const dispatch = useDispatch()

  const endTour = () => {
    dispatch(toggleTour(false))
  }

  return (
    <>
      <Tour
        steps={steps}
        isOpen={isOpen}
        onRequestClose={() => dispatch(toggleTour(false))}
        className="helper"
        rounded={5}
        accentColor="#696969"
        inViewThreshold={3}
        closeWithMask={false}
        lastStepNextButton={
          questionId && (
            <Button variant="light" text="end tour" onClick={endTour} />
          )
        }
      />
    </>
  )
}

export default ProductTour
