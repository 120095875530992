import { History } from 'history'

export const LOG_IN_WITH_GOOGLE = 'LOG_IN_WITH_GOOGLE'
export const LOG_IN_WITH_ACCOUNT = 'LOG_IN_WITH_ACCOUNT'
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS'
export const LOG_OUT = 'LOG_OUT'
export const SAVE_TEST_ID = 'SAVE_TEST_ID'

export type AuthenticatedUser = {
  id: string
  firstName: string
  lastName: string
  email: string
  avatar: string
}

export type LogInState = {
  user?: AuthenticatedUser
}

export interface SaveTestIdAction {
  type: typeof SAVE_TEST_ID
  payload: string
}

export type LogInWithGoogleAction = {
  type: typeof LOG_IN_WITH_GOOGLE
  payload: {
    idToken: string
    history: History
  }
}

export type LogInWithAccountAction = {
  type: typeof LOG_IN_WITH_ACCOUNT
  payload: {
    email: string
    password: string
    history: History
  }
}

export type LogInSuccessAction = {
  type: typeof LOG_IN_SUCCESS
  payload: AuthenticatedUser
}

export type LogOutAction = {
  type: typeof LOG_OUT
}

export type AuthActions =
  | LogInWithGoogleAction
  | LogInWithAccountAction
  | LogInSuccessAction
  | LogOutAction
  | SaveTestIdAction
