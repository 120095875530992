import React from 'react'

import QuestionImage from '../QuestionImage'
import QuestionOptions from '../QuestionOptions'
import QuestionScript from '../QuestionScript'
import './styles.scss'
import Heading from 'common/components/Heading'
import { Question } from 'pages/test/redux/types'

type QuestionProps = {
  question: Question
  questionNumber: number
}

const QuestionComponent = ({ question, questionNumber }: QuestionProps) => {
  return (
    <>
      <div className="test-question__body">
        <div className="test-question__content">
          <Heading
            mainHeading={`Q${questionNumber}. ${question.content}`}
            variant="tertiary"
          />
          {question.image ? <QuestionImage image={question.image} /> : ''}
        </div>
        <div className="test-question__answer">
          {question.questionType === 'freetext' ? (
            <QuestionScript questionId={question.id} />
          ) : (
            <QuestionOptions
              questionId={question.id}
              type={question.questionType}
              options={question.options}
            />
          )}
        </div>
      </div>
    </>
  )
}

QuestionComponent.DisplayName = 'QuestionComponent'
export default QuestionComponent
