import React, { FC } from 'react'
import { TextField } from '@material-ui/core'
import { useField, FieldAttributes } from 'formik'

const FormikMuiTextField: FC<
  {
    label: string
    disabled?: boolean
    required?: boolean
    inputProps?: any
    type?: string
    className?: any
    size?: 'small' | 'medium'
    variant?: string
  } & FieldAttributes<{}>
> = ({
  label,
  disabled = false,
  required = false,
  type = 'text',
  inputProps,
  className,
  size,
  variant = 'outlined',
  ...textFieldProps
}) => {
  const [field, meta] = useField<{}>(textFieldProps)
  const errorText = meta.error && meta.touched ? meta.error : ''
  if (field.value === null) {
    field.value = ''
  }
  return (
    <TextField
      helperText={errorText}
      error={!!errorText}
      label={label}
      InputLabelProps={{ style: { pointerEvents: 'auto' } }}
      color="primary"
      fullWidth
      disabled={disabled}
      required={required}
      type={type}
      variant={variant}
      className={className}
      size={size}
      {...field}
      {...inputProps}
    />
  )
}

export default FormikMuiTextField
