import { LOG_OUT, LogInState, AuthActions, LOG_IN_SUCCESS } from './types'

const initialState: LogInState = {}

export function auth(
  state: LogInState = initialState,
  action: AuthActions
): LogInState {
  switch (action.type) {
    case LOG_IN_SUCCESS:
      return {
        ...state,
        user: action.payload,
      }

    case LOG_OUT:
      delete state['user']
      return {
        ...state,
      }

    default:
      return state
  }
}
