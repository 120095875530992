import React from 'react'

import { Choice } from 'pages/test/redux/types'
import './styles.scss'

interface QuestionOptionProps {
  questionType: string
  option: Choice
  checked: (id: string) => boolean
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
}
const QuestionOption = ({
  questionType,
  option,
  checked,
  onChangeHandler,
}: QuestionOptionProps) => {
  return (
    <div className="option__group">
      <input
        className="option__input"
        type={questionType}
        id={option.id}
        onChange={(event) => onChangeHandler(event)}
        checked={checked(option.id)}
      />
      <label htmlFor={option.id} className="option__label">
        <span
          className={`option__button ${
            questionType === 'checkbox' ? 'checkbox' : ''
          }`}
        />
        <p className="option__content">{option.content}</p>
      </label>
    </div>
  )
}

QuestionOption.DisplayName = 'QuestionOption'
export default QuestionOption
