import React from 'react'
import { useSelector } from 'react-redux'

import useBookmark from 'pages/topic/hooks/useBookmark'
import './styles.scss'
import { RootState } from 'redux/reducers'

type ProgressBarProps = {
  questionId: string
  topicName: string
  currentQuestion: string
  handleClick: (questionId: string) => void
}

const ProgressOption = ({
  questionId,
  topicName,
  currentQuestion,
  handleClick,
}: ProgressBarProps) => {
  const { bookmarked } = useBookmark(topicName, questionId)
  const targetQuestion = questionId
  const isCurrentQuestion = questionId === currentQuestion
  const { answered, fetched } = useSelector((state: RootState) => {
    const answeredQuestions = state.test.test?.topics.find(
      (topic) => topic.name === topicName
    )?.answeredQuestions
    const answered =
      answeredQuestions?.find((q) => q.questionId === questionId) === undefined
        ? state.topic.answeredQuestions.find(
          (q) => q.questionId === questionId
        ) === undefined
          ? false
          : true
        : true

    return {
      answered,
      fetched:
        state.test.questions.find((q) => q.id === questionId) === undefined
          ? false
          : true,
    }
  })

  return (
    <>
      <button
        className="progressOption"
        onClick={() => {
          handleClick(targetQuestion)
        }}
        disabled={(!answered && !fetched && !bookmarked) || isCurrentQuestion}
      >
        <li
          className={`${
            isCurrentQuestion
              ? bookmarked
                ? 'onProgress-bookmarked'
                : 'onProgress'
              : bookmarked
                ? 'bookmarked'
                : !answered
                  ? 'disabled'
                  : ''
          }`}
        />
      </button>
    </>
  )
}

ProgressOption.DisplayName = 'ProgressOption'
export default ProgressOption
