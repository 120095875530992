import { combineReducers } from 'redux'

import { test } from 'pages/test/redux/reducers'
import { auth } from 'pages/auth/redux/reducers'
import { topic } from 'pages/topic/redux/reducers'
import { common } from 'common/redux/reducers'

const rootReducer = combineReducers({
  test,
  auth,
  topic,
  common,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
