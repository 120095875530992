import { Difficulty } from '../../test/redux/types'

export const TOGGLE_BOOKMARK = 'TOGGLE_BOOKMARK'
export const SAVE_ANSWER = 'SAVE_ANSWER'
export const SAVE_ANSWERS = 'SAVE_ANSWERS'
export const EXTRACT_ANSWERS = 'EXTRACT_ANSWERS'
export const FINISH_FETCH = 'FINISH_FETCH'
export const TOGGLE_NAVIGATION = 'TOGGLE_NAVIGATION'

export type AnsweredQuestion = {
  questionId: string
  optionId: string | string[] | null
  answerText: string | null
}

export type Topic = {
  id: string
  name: string
  description: string
  icon: string
  answeredQuestions: AnsweredQuestion[]
  questions: TestQuestionIdObj[]
}

export type TestQuestionIdObj = {
  testQuestionId: string
  questionId: string
  difficulty: Difficulty
}

export type Bookmark = {
  topicName: string
  questionIds: string[]
}

export interface TopicState {
  bookmarks: Bookmark[]
  answeredQuestions: AnsweredQuestion[]
  finishFetch: string[]
  toggleNavigation: boolean
}

export type ToggleBookmarkAction = {
  type: typeof TOGGLE_BOOKMARK
  payload: {
    topicName: string
    questionId: string
  }
}

export type ToggleNavigationAction = {
  type: typeof TOGGLE_NAVIGATION
}

export type SaveAnswerAction = {
  type: typeof SAVE_ANSWER
  payload: AnsweredQuestion
}

export type SaveAnswersAction = {
  type: typeof SAVE_ANSWERS
  payload: AnsweredQuestion[]
}

export type ExtractAnswersAction = {
  type: typeof EXTRACT_ANSWERS
  payload: AnsweredQuestion[]
}

export type FinishFetchAction = {
  type: typeof FINISH_FETCH
  payload: string
}

export type TopicActions =
  | ToggleBookmarkAction
  | SaveAnswerAction
  | SaveAnswersAction
  | ExtractAnswersAction
  | FinishFetchAction
  | ToggleNavigationAction
