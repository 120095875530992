import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import useCurentUser from 'pages/auth/hooks/useCurrentUser'
import { saveTestId } from 'pages/test/redux/actions'

export default function LandingPage() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { testId } = useParams()
  const currentUser = useCurentUser()

  useEffect(() => {
    if (testId) {
      dispatch(saveTestId(testId))
    }
    if (currentUser) {
      history.push('/')
    }
  }, [dispatch, history, testId, currentUser])

  return null
}
