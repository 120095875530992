import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import './styles.scss'
import { toggleTour } from 'common/redux/actions'
import Button from 'common/components/Button'

const SkipTour = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const endTour = () => {
    dispatch(toggleTour(false))
    history.push('/')
  }

  return (
    <div className="skipButton-container">
      <Button
        variant="yellow"
        size="large"
        text="skip and go to test"
        onClick={endTour}
      />
    </div>
  )
}

export default SkipTour
