import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import GoogleLogin from 'react-google-login'

import { loginWithGoogle } from '../../redux/actions'
import { showNotification } from 'common/redux/actions'

const GoogleSignIn = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const responseGoogle = (response: any) => {
    if (response.error) {
      switch (response.error) {
        case 'popup_closed_by_user':
          return
        default:
          return dispatch(showNotification('Google login error', 'error'))
      }
    }
    if (response) dispatch(loginWithGoogle(response.tokenObj.id_token, history))
  }

  return (
    <>
      <GoogleLogin
        clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`}
        buttonText="Login with Google"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        onAutoLoadFinished={responseGoogle}
        cookiePolicy={'single_host_origin'}
      />
    </>
  )
}

export default GoogleSignIn
