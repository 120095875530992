import { History } from 'history'

import {
  LOG_IN_WITH_GOOGLE,
  LOG_IN_WITH_ACCOUNT,
  LOG_OUT,
  AuthActions,
  AuthenticatedUser,
  LOG_IN_SUCCESS,
} from './types'

export function loginWithGoogle(
  idToken: string,
  history: History
): AuthActions {
  return {
    type: LOG_IN_WITH_GOOGLE,
    payload: {
      idToken,
      history,
    },
  }
}
export function loginWithAccount(
  email: string,
  password: string,
  history: History
): AuthActions {
  return {
    type: LOG_IN_WITH_ACCOUNT,
    payload: {
      email,
      password,
      history,
    },
  }
}

export function loginSuccess(user: AuthenticatedUser): AuthActions {
  return {
    type: LOG_IN_SUCCESS,
    payload: user,
  }
}

export function logout(): AuthActions {
  return {
    type: LOG_OUT,
  }
}
