import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon } from 'react-icons-kit'
import { closeRound } from 'react-icons-kit/ionicons/closeRound'
import { useLocation } from 'react-router-dom'
import { setSidebar } from 'common/redux/actions'

import Heading from 'common/components/Heading'
import ProgressBar from '../ProgressBar'
import './styles.scss'
import { ReactComponent as Logo } from 'logo.svg'
import { RootState } from 'redux/reducers'
import exampleTest from 'pages/topic/redux/exampleTest'
import arrow from 'assets/images/arrow-down.png'
import useTest from 'pages/test/hooks/useTest'

const SideBar = () => {
  const colorText = ['Unanswered', 'Answered', 'Bookmarked']
  const dispatch = useDispatch()
  const topicsList = useSelector((state: RootState) => state.test.test?.topics)

  const location = useLocation()
  // url: /topics/:topicName/:questionId
  const questionId = location.pathname.split('/')[3]
  let topicName = location.pathname.split('/')[2]
  topicName = decodeURIComponent(topicName)

  const [isOpenList, setIsOpenList] = useState<string[]>([topicName])

  // fetch answered questions again
  useTest()

  useEffect(() => {
    setIsOpenList([topicName])
  }, [topicName])

  const handleTopicClick = (topicName: string) => {
    if (isOpenList.includes(topicName)) {
      const newList = isOpenList.filter((topic) => topic !== topicName)
      setIsOpenList(newList)
    } else {
      const newList = [...isOpenList]
      newList.push(topicName)
      setIsOpenList(newList)
    }
  }

  const closeSidebar = (open: boolean) => {
    dispatch(setSidebar(open))
  }

  return (
    <>
      <Icon
        icon={closeRound}
        size={20}
        onClick={() => closeSidebar(false)}
        className="side-bar__exitIcon"
      />
      <div className="side-bar__topics">
        {topicName === 'Example' ? (
          <div
            className={`side-bar__topic ${
              isOpenList.includes(exampleTest.name) ? 'expanding' : ''
            }`}
          >
            <div
              role="button"
              className="side-bar__heading"
              onClick={() => handleTopicClick(exampleTest.name)}
              onKeyDown={() => handleTopicClick(exampleTest.name)}
              tabIndex={0}
            >
              <Heading mainHeading={exampleTest.name} variant="tertiary" />
              <button>
                <img
                  src={arrow}
                  alt="expand topic icon"
                  className="side-bar__arrow"
                />
              </button>
            </div>
            <ProgressBar
              questionId={questionId!}
              topicName={exampleTest.name}
              questions={exampleTest.questions}
            />
          </div>
        ) : (
          topicsList &&
          topicsList.map((topic, index) => (
            <div
              key={topic.name}
              className={`side-bar__topic ${
                isOpenList.includes(topic.name) ? 'expanding' : ''
              }`}
            >
              <div
                role="button"
                className="side-bar__heading"
                onClick={() => handleTopicClick(topic.name)}
                onKeyDown={() => handleTopicClick(exampleTest.name)}
                tabIndex={index}
              >
                <Heading mainHeading={topic.name} variant="tertiary" />
                <button>
                  <img
                    src={arrow}
                    alt="expand topic icon"
                    className="side-bar__arrow"
                  />
                </button>
              </div>
              <ProgressBar
                questionId={questionId!}
                topicName={topic.name}
                questions={topic.questions}
              />
            </div>
          ))
        )}
      </div>
      <div className="side-bar__legends">
        {colorText.map((color) => {
          return (
            <div
              key={color}
              className={`legend legend--${
                color === 'Unanswered'
                  ? 'unanswered'
                  : color === 'Answered'
                    ? 'answered'
                    : 'bookmarked'
              }`}
            >
              {color}
            </div>
          )
        })}
      </div>
      <div className="side-bar__warning">
        You can only switched between answered and bookmarked questions.
      </div>
      {window.screen.width < 767 ? (
        <div className="side-bar__logo">
          <Logo />
        </div>
      ) : null}
    </>
  )
}

export default SideBar
