import axios from 'axios'

import { Feedback } from './../redux/types'

export default {
  async sendFeedback(testId: string, feedback: Feedback) {
    return await axios
      .put(`tests/${testId}/feedback`, feedback)
      .then((resp) => resp.data)
  },
}
