import React from 'react'

import euLogo from 'assets/images/EU_logo.png'
import euFlag from 'assets/images/EU_flag.png'
import './styles.scss'

const Footer = () => (
  <div className="footer">
    <div className="footer-container">
      <p>©{new Date().getFullYear()} Integrify. We develop code(rs)</p>
      <div className="footer-content">
        <p className="footer-content__text">Supported by</p>
        <div className="footer-content__icon">
          <img src={euFlag} alt="EU flag" className="footer-image" />
          <img src={euLogo} alt="EU logo" className="footer-image" />
        </div>
      </div>
    </div>
  </div>
)

Footer.DisplayName = 'Footer'
export default Footer
