import React from 'react'

import SkipTour from 'common/components/SkipTour'

export const homeSteps = [
  {
    selector: '',
    content: (
      <div>
        Welcome to our Introduction Tour! Please find the following instructions
        on how to do the test. If you are already familiar with the page, you
        can skip the tour and enter the test immediately. <br />
        <SkipTour />
      </div>
    ),
  },
  {
    selector: '.timer',
    content: (
      <div>
        There is a time limit to do the test. Please be aware of it! <br />
        <SkipTour />
      </div>
    ),
  },
  {
    selector: '.tour-icon',
    content: (
      <div>
        You can re-enter this Introduction Tour by clicking on this icon! <br />
        <SkipTour />
      </div>
    ),
    stepInteraction: false,
  },
  {
    selector: '.btn--dark',
    content: (
      <div>
        When you complete all the topics, click here to finish the test. Once
        you did, you could not change your answers anymore. Then, you will be
        moved to the feedpage page.
        <br />
        <SkipTour />
      </div>
    ),
    stepInteraction: false,
  },
  {
    selector: '.topic-card',
    content: (
      <div>
        Here is an example of one topic in your test. <br />
        <SkipTour />
      </div>
    ),
    stepInteraction: false,
  },
  {
    selector: '.btn--yellow',
    content: (
      <div>
        Click this button to enter a topic page, where you will get more
        information about the topic. <strong>Click</strong> to proceed to the
        next step! <br />
        <SkipTour />
      </div>
    ),
  },
]

export const topicSteps = [
  {
    selector: '.topic-content__description',
    content: (
      <div>
        These are the topic title and its description. <br />
        <SkipTour />
      </div>
    ),
    stepInteraction: false,
  },
  {
    selector: '.big',
    content: (
      <div>
        This will show your progress of the current topic. <br />
        <SkipTour />
      </div>
    ),
  },
  {
    selector: '.btn--dark',
    content: (
      <div>
        This button is for returning to the homepage. <br />
        <SkipTour />
      </div>
    ),
    stepInteraction: false,
  },
  {
    selector: '.btn--outline',
    content: (
      <div>
        This button is for reseting the example test. So, you can practice until
        you are familiar with our test system before taking the actual test.{' '}
        <br />
        <i>(Only available in this example test.)</i>
        <SkipTour />
      </div>
    ),
    stepInteraction: false,
  },
  {
    selector: '.btn--yellow',
    content: (
      <div>
        Click this button to begin the test. In the actual topic page, the timer
        will start counting down! <strong>Click</strong> to proceed to the next
        step.
        <br />
        <i>(We are in the example topic, it won't trigger the timer.)</i>
        <SkipTour />
      </div>
    ),
  },
]

export const testSteps = [
  {
    selector: '.test__topic-name',
    content: (
      <div>
        This is the title of the current topic you are taking. <br />
        <SkipTour />
      </div>
    ),
    stepInteraction: false,
  },
  {
    selector: '.test__body',
    content: (
      <div>
        This is an example question. <br />
        <SkipTour />
      </div>
    ),
    stepInteraction: false,
  },
  {
    selector: '.test-question__content',
    content: (
      <div>
        There are 3 types of questions: multiple choice, checkbox and
        constructed respone. For textbox question type, you can choose multiple
        answers. <br />
        <SkipTour />
      </div>
    ),
  },
  {
    selector: '.test-question__answer',
    content: (
      <div>
        <strong>Choose</strong> an answer. <br />
        <SkipTour />
      </div>
    ),
  },
  {
    selector: '.header__test-button',
    content: (
      <div>
        Side bar is where you can navigate easily between questions. For the
        first time, it will automically be opened. You can toggle the side bar
        by <strong>clicking</strong> this icon!
        <br />
        <SkipTour />
      </div>
    ),
    observe: '.test--container',
  },
  {
    selector: '.side-bar__topics',
    content: (
      <div>
        From here, you can only navigate between answered and bookmarked
        questions.
        <br />
        <SkipTour />
      </div>
    ),
    stepInteraction: false,
  },
  {
    selector: '.btn--dark',
    content: (
      <div>
        This button is for returning to the homepage. <br />
        <SkipTour />
      </div>
    ),
    stepInteraction: false,
  },
  {
    selector: '.btn-bookmark',
    content: (
      <div>
        To bookmark the current question for later review, you can click this
        button. <br />
        <SkipTour />
      </div>
    ),
  },
  {
    selector: '.btn--yellow',
    content: (
      <div>
        You can only move to the next question by clicking this button. <br />
        <SkipTour />
      </div>
    ),
    stepInteraction: false,
  },
  {
    selector: '',
    content: (
      <div>
        Everything is all set. You can now either finish the Example test or
        enter the real test. <br />
        <SkipTour />
      </div>
    ),
  },
]
