import React from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import './styles.scss'
import useAnswer from 'pages/test/hooks/useAnswer'
import useBookmark from 'pages/topic/hooks/useBookmark'
import { sendAnswer } from 'pages/test/redux/actions'

interface QuestionScriptProps {
  questionId: string
}

const QuestionScript = ({ questionId }: QuestionScriptProps) => {
  const { currentAnswer, setCurrentAnswer } = useAnswer(questionId)
  const initialValue = currentAnswer?.answerText ?? ''
  const { topicName } = useParams()
  const { bookmarked } = useBookmark(topicName, questionId)
  const dispatch = useDispatch()

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target
    setCurrentAnswer((answer) => {
      return {
        ...answer!,
        answerText: value,
      }
    })
    dispatch(sendAnswer(bookmarked))
  }
  return (
    <>
      <textarea
        className="test-question__script"
        name="textarea"
        placeholder="Type your answer here!"
        value={initialValue}
        onChange={(event) => onChangeHandler(event)}
      />
    </>
  )
}

QuestionScript.DisplayName = 'QuestionScript'
export default QuestionScript
