import React from 'react'

import { Topic } from 'pages/topic/redux/types'
import './styles.scss'
import Description from 'common/components/Description'
import Heading from 'common/components/Heading'
import { ReactComponent as QuestionMark } from 'assets/images/question_mark.svg'

interface TopicDescriptionProps {
  topic: Topic
}

const TopicDescription = ({ topic }: TopicDescriptionProps) => {
  const handleHeading = (text: string) => {
    //regex to search for title with '/'
    const regex = /[a-zA-Z0-9]+\/[a-zA-Z0-9]+/
    const found = text.match(regex)
    if (found && found.length > 0) {
      //add space after '/' to support responsiveness
      const newText = text.replace('/', '/ ')
      return newText
    }
    return text
  }
  return (
    <>
      <Heading mainHeading={handleHeading(topic.name)} variant="primary" />
      <Description
        content={topic.description}
        type="fullLeft"
        variant="light"
      />
      <div className="question-number-container">
        <div className="questionMark">
          <QuestionMark />
        </div>
        <span className="question-number">
          {topic.questions.length} questions
        </span>
      </div>
    </>
  )
}

TopicDescription.DisplayName = 'TopicDescription'
export default TopicDescription
