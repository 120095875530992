import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { th } from 'react-icons-kit/fa/th'

import { ReactComponent as Logo } from 'logo.svg'
import './styles.scss'
import Timer from '../Timer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/reducers'
import { setSidebar } from 'common/redux/actions'

const Header = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const isSidebarOpen = useSelector(
    (state: RootState) => state.common.isSidebarOpen
  )
  let shouldRender = false

  if (location.pathname !== '/login' && location.pathname !== '/feedback') {
    shouldRender = true
  }

  const toggleSidebar = () => {
    dispatch(setSidebar(!isSidebarOpen))
  }

  return (
    <div className="header">
      {shouldRender && (
        <div
          className={`header__test-button header__test-button--${
            isSidebarOpen ? 'hide' : ''
          }`}
        >
          <Icon
            icon={th}
            size={window.screen.width < 480 ? 32 : 24}
            onClick={toggleSidebar}
          />
        </div>
      )}
      <div className="header__container">
        <Link to="/" className="logo-link">
          <Logo />
        </Link>
        {shouldRender && <Timer />}
      </div>
    </div>
  )
}

Header.DisplayName = 'Header'
export default Header
