import React from 'react'
import { Icon } from 'react-icons-kit'
import { checkCircle } from 'react-icons-kit/fa/checkCircle'
import { exclamationTriangle } from 'react-icons-kit/fa/exclamationTriangle'
import { timesCircle } from 'react-icons-kit/fa/timesCircle'

import Button from 'common/components/Button'
import Heading from 'common/components/Heading'
import './styles.scss'

type AlertType = 'success' | 'error' | 'warning' | 'warningFinish' | undefined

type Props = {
  toggleAlert: boolean
  type: AlertType
  text: string | undefined
  onDeclineClick?: () => void
  onApproveClick?: () => void
}

const Alert = ({
  toggleAlert,
  type,
  text,
  onDeclineClick,
  onApproveClick,
}: Props) => {
  return (
    <div className={`alert alert--${toggleAlert ? 'display' : 'hide'}`}>
      <div className="alert__card">
        {type === 'success' && (
          <Icon icon={checkCircle} size={60} className="success-icon" />
        )}
        {(type === 'warning' || type === 'warningFinish') && (
          <Icon icon={exclamationTriangle} size={60} className="warning-icon" />
        )}
        {type === 'error' && (
          <Icon icon={timesCircle} size={60} className="error-icon" />
        )}
        <Heading mainHeading={text!} variant="tertiary" />
        {type === 'warningFinish' ? (
          <div className="warning-btns">
            <Button
              variant="light"
              text="no"
              size="regular"
              onClick={onDeclineClick}
            />
            <Button
              variant="yellow"
              text="yes"
              size="regular"
              onClick={onApproveClick}
            />
          </div>
        ) : (
          <div className="alert-btn">
            <Button
              variant="light"
              text="ok"
              size="regular"
              onClick={onDeclineClick}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Alert
