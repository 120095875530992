import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import useBookmark from 'pages/topic/hooks/useBookmark'
import { RootState } from 'redux/reducers'
import ProgressOption from '../ProgressOption'
import './styles.scss'
import { showNotification } from 'common/redux/actions'
import { resetCurrentAnswer } from 'pages/test/redux/actions'
import { TestQuestionIdObj } from 'pages/topic/redux/types'

type ProgressBarProps = {
  questions: TestQuestionIdObj[]
  topicName: string
  questionId: string
}

const ProgressBar = ({
  questions,
  topicName,
  questionId,
}: ProgressBarProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const currentAnswer = useSelector(
    (state: RootState) => state.test.currentAnswer
  )

  const { bookmarked } = useBookmark(topicName, questionId)

  const handleClick = (targetQuestion: string) => {
    const optionLength = currentAnswer?.optionId?.length ?? 0
    const textLength = currentAnswer?.answerText?.trim().length ?? 0
    if (
      (currentAnswer?.optionId !== null && optionLength > 0) ||
      (currentAnswer?.answerText !== null && textLength > 0) ||
      bookmarked
    ) {
      dispatch(resetCurrentAnswer())
      history.push(`/topics/${encodeURIComponent(topicName)}/${targetQuestion}`)
    } else {
      dispatch(
        showNotification(
          'You can not leave answer blank, unless you bookmark it!',
          'error'
        )
      )
    }
  }

  return (
    <div className="progressbar">
      <ul className="progressbar__wrapper">
        {questions.map((question) => {
          return (
            <ProgressOption
              key={question.questionId}
              questionId={question.questionId}
              topicName={topicName}
              currentQuestion={questionId}
              handleClick={handleClick}
            />
          )
        })}
      </ul>
    </div>
  )
}

ProgressBar.DisplayName = 'ProgressBar'

export default ProgressBar
