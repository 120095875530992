import React from 'react'

import ProgressCircle from 'common/components/ProgressCircle'
import { Topic } from 'pages/topic/redux/types'

type Props = {
  topic: Topic
  size: 'big' | 'small'
}

const TopicProgress = ({ topic, size }: Props) => {
  let percentage: number = 0
  let text = ''

  if (topic?.questions && topic.answeredQuestions) {
    if (topic.name === 'Example') {
      if (localStorage.getItem('Example Answers')) {
        const storedExampleAns = JSON.parse(
          localStorage.getItem('Example Answers')!
        ).length as number
        percentage = Math.round(
          (storedExampleAns / topic?.questions.length) * 100
        )
        text = `${storedExampleAns}/${topic?.questions.length}`
      } else {
        percentage = 0
        text = '0/3'
      }
    } else {
      //AnsweredQuestions array may contain several objects with the same questionId
      //because checkbox may have multiple optionIds
      const distinctAnsweredQuestions = Array.from(
        new Set(
          topic.answeredQuestions
            .filter((answer) => answer.questionId)
            .map((answer) => answer.questionId)
        )
      )

      percentage = Math.round(
        (distinctAnsweredQuestions.length / topic?.questions.length) * 100
      )
      text = `${distinctAnsweredQuestions.length}/${topic?.questions.length}`
    }
  }

  return (
    <>
      <ProgressCircle
        variant={size}
        percentage={percentage}
        text={text}
        strokeWidth={6}
      />
    </>
  )
}

TopicProgress.DisplayName = 'TopicProgress'
export default TopicProgress
