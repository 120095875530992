export default [
  {
    id: 'example-1',
    questionType: 'multiplechoice',
    content: 'Which of the given shapes would complete the sequence?',
    image: 'https://a.storyblok.com/f/51116/2016x1425/949358a99a/b1.jpg',
    difficulty: 'easy',
    options: [
      {
        id: 'a',
        content: 'A',
        image: 'https://i.postimg.cc/N01pw7kH/choice-1.png',
      },
      {
        id: 'b',
        content: 'B',
        image: 'https://i.postimg.cc/CKG5Hzw0/choice-2.png',
      },
      {
        id: 'c',
        content: 'C',
        image: 'https://i.postimg.cc/DwDpdwBx/choice-3.png',
      },
      {
        id: 'd',
        content: 'D',
        image: 'https://i.postimg.cc/CL8RLv9d/choice-4.png',
      },
    ],
  },
  {
    id: 'example-2',
    questionType: 'freetext',
    content: 'Please explain the following code',
    image:
      'https://cdn-media-1.freecodecamp.org/images/1*mXjNHOx9bbQ5D4sSUAX2Lg.png',
    difficulty: 'medium',
    options: [],
  },
  {
    id: 'example-3',
    questionType: 'checkbox',
    content:
      'How do you feel about this sample test? (There might be more than 1 correct answer)',
    image: 'https://a.storyblok.com/f/51116/2016x1425/064bd79e2c/b3.jpg',
    difficulty: 'hard',
    options: [
      { id: 'a', content: 'Great!', image: '' },
      { id: 'b', content: 'Super great!', image: '' },
      { id: 'c', content: 'Soooooooo greattttttttttttt!', image: '' },
      { id: 'd', content: 'I am going to report this !!!', image: '' },
    ],
  },
]
