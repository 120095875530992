import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'

import rootSaga from './sagas'
import rootReducer, { RootState } from './reducers'

const init: RootState = {
  test: {
    questions: [],
  },
  auth: {},
  topic: {
    bookmarks: [],
    answeredQuestions: [],
    finishFetch: [],
    toggleNavigation: false,
  },
  common: {
    notification: null,
    isTourOpen: true,
    isSidebarOpen: false,
  },
}

export default function configureStore() {
  let initialState
  const loadedState = localStorage.getItem('state')

  if (loadedState !== null) {
    initialState = JSON.parse(loadedState)
  } else initialState = init

  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [sagaMiddleware]
  const middleWareEnhancer = applyMiddleware(...middlewares)

  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(middleWareEnhancer)
  )

  sagaMiddleware.run(rootSaga)

  return store
}
