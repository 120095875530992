import { takeEvery, put, call, select, fork } from 'redux-saga/effects'

import { showNotification } from 'common/redux/actions'
import { SEND_FEEDBACK, SendFeedbackAction } from './types'
import { RootState } from 'redux/reducers'
import { logout } from 'pages/auth/redux/actions'
import API from '../services/api'

function* sendNotification() {
  yield takeEvery(SEND_FEEDBACK, function* (action: SendFeedbackAction) {
    try {
      const state: RootState = yield select()
      if (state.auth.user && state.test!.testId) {
        yield call(API.sendFeedback, state.test!.testId, action.payload)
      }
      const message = 'Thank you for your feedback!'
      yield put(showNotification(message, 'success'))
      yield put(logout())
    } catch (error) {
      showNotification(error.message || error.response!.data.message, 'error')
    }
  })
}

export default [sendNotification].map(fork)
