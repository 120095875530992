import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'redux/reducers'

import { saveAnswers } from '../redux/actions'
import exampleTest from 'pages/topic/redux/exampleTest'
import { AnsweredQuestion } from '../redux/types'

export default function useTopic(topicName: string) {
  topicName = decodeURIComponent(topicName)

  const frontendTopics = useSelector((state: RootState) => state.topic)
  const backendTopics = useSelector(
    (state: RootState) => state.test.test?.topics
  )

  const backendAnsweredQuestions =
    backendTopics &&
    backendTopics.reduce((prev: AnsweredQuestion[], cur) => {
      prev.push(...cur.answeredQuestions)

      return prev
    }, [])

  const dispatch = useDispatch()
  const { topic } = useSelector((state: RootState) => {
    const topic =
      topicName === 'Example'
        ? exampleTest
        : state.test.test?.topics.find((tp) => tp.name === topicName)
    const answeredQuestions = topic?.answeredQuestions

    return {
      topic,
      answeredQuestions,
    }
  })

  useEffect(() => {
    // If there are answered questions in backend but not in frontend, we save those into redux
    if (
      frontendTopics.answeredQuestions.length === 0 &&
      backendAnsweredQuestions &&
      backendAnsweredQuestions.length > 0
    ) {
      dispatch(saveAnswers(backendAnsweredQuestions))
    }
  }, [backendAnsweredQuestions, dispatch, frontendTopics.answeredQuestions])

  return topic
}
