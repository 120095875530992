import { Difficulty } from '../../test/redux/types'

export default {
  id: 'Example',
  name: 'Example',
  description:
    'This is an example of what a topic would look like. You can try out our system as many times as you want',
  icon: '',
  answeredQuestions: [],
  questions: [
    {
      testQuestionId: 'example-1',
      questionId: 'example-1',
      difficulty: Difficulty.EASY,
    },
    {
      testQuestionId: 'example-2',
      questionId: 'example-2',
      difficulty: Difficulty.MEDIUM,
    },
    {
      testQuestionId: 'example-3',
      questionId: 'example-3',
      difficulty: Difficulty.HARD,
    },
  ],
}
