import React from 'react'
import { Switch, Route } from 'react-router-dom'

import HomePage from 'pages/home'
import LoginPage from 'pages/auth'
import TopicPage from 'pages/topic'
import FeedbackPage from 'pages/feedback'
import LandingPage from 'pages/landing'
import QuestionPage from 'pages/test'

const Routes = () => (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <Route exact path="/login" component={LoginPage} />
    <Route exact path="/tests/:testId" component={LandingPage} />
    <Route exact path="/feedback" component={FeedbackPage} />
    <Route exact path="/topics/:topicName" component={TopicPage} />
    <Route
      exact
      path="/topics/:topicName/:questionId"
      component={QuestionPage}
    />
  </Switch>
)

export default Routes
