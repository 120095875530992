import {
  takeEvery,
  put,
  call,
  select,
  fork,
  takeLatest,
  delay,
} from 'redux-saga/effects'

import {
  FINISH_TEST,
  FETCH_TEST,
  FetchTestAction,
  FETCH_QUESTION,
  FetchQuestionAction,
  SendAnswerAction,
  START_TEST,
  SEND_ANSWER,
  AnswerPayload,
} from './types'
import { showNotification } from 'common/redux/actions'
import API from '../services/api'
import { RootState } from 'redux/reducers'
import {
  fetchTestSuccess,
  fetchQuestionSuccess,
  finishTestSuccess,
  startTestSuccess,
} from './actions'
import { saveAnswer } from 'pages/topic/redux/actions'
import { AnsweredQuestion } from 'pages/topic/redux/types'
import { logout } from 'pages/auth/redux/actions'

function* showError(error: any) {
  const message = error.response?.data.message || error.message
  if (error.response?.data.statusCode === 500) {
    const mess = 'Internal server error. Please try again later.'
    yield put(showNotification(mess, 'error'))
  }
  if (message.includes('Invalid param id')) {
    const mess =
      'Invalid test ID. Please contact Integrify again to get a new ID.'
    yield put(showNotification(mess, 'error'))
  } else yield put(showNotification(message, 'error'))
  if (
    error.response?.data.statusCode === 401 ||
    error.response?.data.statusCode === 403 ||
    message.includes('Invalid param id')
  ) {
    yield put(logout())
  }
}

function* finishTest() {
  yield takeEvery(FINISH_TEST, function* () {
    try {
      const state: RootState = yield select()
      if (state.auth.user && state.test.testId) {
        yield call(API.finishTest, state.test.testId, state.auth.user.id)
      }
    } catch (error) {
      yield showError(error)
    } finally {
      yield put(finishTestSuccess())
    }
  })
}

function* startTest() {
  yield takeEvery(START_TEST, function* () {
    try {
      const state: RootState = yield select()
      if (state.auth.user && state.test.testId) {
        yield call(API.startTest, state.test.testId, state.auth.user.id)
      }
    } catch (error) {
      yield showError(error)
    } finally {
      yield put(startTestSuccess())
    }
  })
}

function* fetchTest() {
  yield takeEvery(FETCH_TEST, function* (action: FetchTestAction) {
    try {
      const test = yield call(API.fetchTest, action.payload.testId)
      yield put(fetchTestSuccess(test))
    } catch (error) {
      yield showError(error)
    }
  })
}

function* fetchQuestion() {
  yield takeEvery(FETCH_QUESTION, function* (action: FetchQuestionAction) {
    try {
      const question = yield call(API.fetchQuestion, action.payload.questionId)
      yield put(fetchQuestionSuccess(question))
    } catch (error) {
      yield showError(error)
    }
  })
}

function* sendAnswer() {
  yield takeLatest(SEND_ANSWER, debounceSendAnswer)
}

function* debounceSendAnswer(action: SendAnswerAction) {
  //Delay for 500ms before performing action
  yield delay(500)
  try {
    const exampleQuestions = ['example-1', 'example-2', 'example-3']
    const state: RootState = yield select()
    if (!state.test.currentAnswer) {
      return
    }

    const { test, questionId, optionId, answerText, student, testQuestionId } =
      state.test.currentAnswer
    if (!exampleQuestions.includes(questionId)) {
      const submittedAnswer: Partial<AnswerPayload> = {
        test,
        questionId,
        student,
        testQuestionId,
      }
      //For radio answers
      if (optionId !== null && typeof optionId === 'string')
        submittedAnswer.optionIds = [optionId]
      //For checkbox answers
      if (optionId !== null && typeof optionId === 'object')
        submittedAnswer.optionIds = optionId
      else submittedAnswer.answerText = answerText
      if (
        (submittedAnswer?.optionIds && submittedAnswer.optionIds.length > 0) ||
        (submittedAnswer?.answerText && submittedAnswer.answerText.length > 0)
      )
        yield call(API.submitAnswer, submittedAnswer)
    }
    const answer: AnsweredQuestion = {
      questionId,
      optionId,
      answerText,
    }
    yield put(saveAnswer(answer))
  } catch (error) {
    yield showError(error)
  }
}

export default [
  startTest,
  finishTest,
  fetchTest,
  fetchQuestion,
  sendAnswer,
].map(fork)
