export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const HIDE_NOTIFICATION = 'HIDE_NOIFICATION'
export const TOGGLE_TOUR = 'TOGGLE_TOUR'
export const SET_SIDEBAR = 'SET_SIDEBAR'

export type NotificationVariant =
  | 'error'
  | 'success'
  | 'warning'
  | 'warningFinish'

export type Notification = {
  message: string
  variant: NotificationVariant
}

export type ToggleTourAction = {
  type: typeof TOGGLE_TOUR
  payload: boolean
}

export type ShowNotificationAction = {
  type: typeof SHOW_NOTIFICATION
  payload: Notification
}

export type HideNotifcationAction = {
  type: typeof HIDE_NOTIFICATION
}

export type SetSidebar = {
  type: typeof SET_SIDEBAR
  payload: boolean
}

export type CommonActions =
  | ShowNotificationAction
  | HideNotifcationAction
  | ToggleTourAction
  | SetSidebar

export type CommonState = {
  notification: Notification | null
  isTourOpen: boolean
  isSidebarOpen: boolean
}
