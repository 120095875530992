import React from 'react'
import './styles.scss'

export type HeadingVariant = 'primary' | 'secondary' | 'tertiary' | 'custom'

interface Props {
  mainHeading: string
  variant?: HeadingVariant
  subHeading?: string
}

const Heading = ({ mainHeading, variant, subHeading }: Props) => {
  return (
    <>
      <h1 className={variant}>{mainHeading}</h1>
      {subHeading && <h4 className={`${variant}--sub`}>{subHeading}</h4>}
    </>
  )
}

export default Heading
