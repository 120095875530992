import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from 'redux/reducers'
import { finishFetch } from 'pages/topic/redux/actions'
import { fetchQuestion } from '../redux/actions'
import exampleQuestions from '../redux/exampleTest'
import exampleTopic from 'pages/topic/redux/exampleTest'

export default function useQuestion(topicName: string, questionId: string) {
  const dispatch = useDispatch()
  const { questions, question, questionNumber, fetchFinished } = useSelector(
    (state: RootState) => {
      const question =
        topicName === 'Example'
          ? exampleQuestions.find((q) => q.id === questionId)
          : state.test.questions.find((q) => q.id === questionId)
      const topic =
        topicName === 'Example'
          ? exampleTopic
          : state.test.test!.topics.find((tp) => tp.name === topicName)
      const questionNumber =
        topic!.questions.findIndex((item) => item.questionId === questionId)! +
        1
      const questions = topic!.questions
      const fetchFinished = state.topic.finishFetch.find(
        (topic) => topic === topicName
      )
        ? true
        : false

      return {
        questions,
        question,
        questionNumber: questionNumber ? questionNumber : 1,
        fetchFinished,
      }
    }
  )

  useEffect(() => {
    if (questionNumber === questions.length) {
      dispatch(finishFetch(topicName))
    }
  }, [dispatch, questionNumber, questions.length, topicName])

  useEffect(() => {
    if (!question) {
      dispatch(fetchQuestion(questionId))
    }
  }, [dispatch, question, questionId])

  return {
    questions,
    question,
    questionNumber,
    fetchFinished,
  }
}
