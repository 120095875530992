import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { History } from 'history'
import { Form, Formik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { string, object } from 'yup'

import useCurentUser from './hooks/useCurrentUser'
import GoogleLogIn from './components/LoginButton'
import Button from '../../common/components/Button/index'
import Heading from 'common/components/Heading'
import Description from 'common/components/Description'
import FormikMuiTextField from 'common/components/FormikMuiTextField'
import { loginWithAccount } from './redux/actions'

import './styles.scss'

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
    width: '300px',
    '& > label, div, p ': {
      fontSize: '1.5rem',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink ': {
      color: '#ffbb0e',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: '1px solid #ffbb0e',
        borderRadius: '6px',
      },
    },
  },
}))

const LoginPage = () => {
  const dispatch = useDispatch()
  const currentUser = useCurentUser()
  const history = useHistory()
  const classes = useStyles()

  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false)
  const handleOnSubmit = (
    values: any,
    history: History,
    setSubmitting: (values: boolean) => void
  ) => {
    dispatch(loginWithAccount(values.email, values.password, history))
    setSubmitting(true)
  }

  useEffect(() => {
    if (currentUser) history.push('/')
  }, [history, currentUser])

  const validationSchema = object().shape({
    email: string().email().required('Email must not be empty'),
    password: string()
      .required('Password must not be empty')
      .min(5, 'Password is too short'),
  })

  return (
    <div className="login-container">
      <div className="login-board">
        <Heading
          mainHeading="Your career as a developer starts here"
          subHeading="Integrify teaches coding to immigrants and connects them with work
          opportunities.Apply now to develop your coding skills, join lectures of software developers with years of experience."
          variant="secondary"
        />
        <h4>
          For more information about Integrify{' '}
          <a href="https://www.integrify.io/">
            <span>Click here.</span>
          </a>
        </h4>

        <div className="login-board__reminder">
          <Description
            content="Please log in to continue with the test."
            type="fullCenter"
            variant="dark"
          />
        </div>
        <div className="login-board__methods">
          <div className="login-board__methods__normal">
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validateOnChange={true}
              validationSchema={validationSchema}
              onSubmit={(values: any, { setSubmitting }) => {
                handleOnSubmit(values, history, setSubmitting)
              }}
            >
              {({ values, setSubmitting, isValid, dirty }) => {
                // Use dirty to disable submit button from the beginning
                isValid && dirty
                  ? setDisabledSubmit(false)
                  : setDisabledSubmit(true)
                return (
                  <Form>
                    <FormikMuiTextField
                      size="medium"
                      label="Email"
                      name="email"
                      inputProps={{
                        classes: {
                          root: classes.root,
                        },
                      }}
                    />
                    <FormikMuiTextField
                      type="password"
                      size="medium"
                      label="Password"
                      name="password"
                      inputProps={{
                        classes: {
                          root: classes.root,
                        },
                      }}
                    />
                    <Button
                      customClassName="login__button"
                      variant="dark"
                      text="login"
                      size="small"
                      onClick={() =>
                        handleOnSubmit(values, history, setSubmitting)
                      }
                      disabled={disabledSubmit}
                    />
                  </Form>
                )
              }}
            </Formik>
          </div>
          <h4 className="login-board__divider">
            Or login with Google if you're Integrify student or applying with
            Gmail account
          </h4>
          <div className="login-board__methods__google">
            <GoogleLogIn />
          </div>
        </div>
      </div>
    </div>
  )
}

LoginPage.DisplayName = 'LoginPage'
export default LoginPage
