import React, { FC } from 'react'

import './styles.scss'

export type VariantType = 'yellow' | 'dark' | 'blue' | 'light' | 'outline'

export type SizeType = 'small' | 'regular' | 'large'

interface ButtonProps {
  variant: VariantType
  disabled?: boolean
  text: string
  width?: 'auto'
  size?: string
  customClassName?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const Button: FC<ButtonProps> = ({
  variant,
  disabled,
  text,
  width,
  customClassName,
  size,
  onClick,
}) => {
  return (
    <button
      className={`btn btn--${variant} btn--${size} ${customClassName}`}
      onClick={onClick}
      disabled={disabled ? true : false}
    >
      {' '}
      <span>{text}</span>
    </button>
  )
}

export default Button
