import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './styles.scss'
import { ReactComponent as QuestionMark } from 'assets/images/question_mark.svg'
import { RootState } from 'redux/reducers'
import { toggleTour } from 'common/redux/actions'

const TourIcon = () => {
  const isOpen = useSelector((state: RootState) => state.common.isTourOpen)
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(toggleTour(!isOpen))
  }
  return (
    <button className="tour-icon" onClick={() => handleClick()}>
      <QuestionMark />
    </button>
  )
}

export default TourIcon
