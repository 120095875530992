import React from 'react'
import { useHistory } from 'react-router'
import clsx from 'clsx'

import './styles.scss'
import { Topic } from 'pages/topic/redux/types'
import Button from 'common/components/Button'
import Description from 'common/components/Description'
import TopicProgress from '../TopicProgress'
import Heading from 'common/components/Heading'
import Image from 'common/components/Image'
import exampleIcon from 'assets/images/Example.svg'

interface TopicCardProps {
  topic: Topic
  isExample?: boolean
}

const TopicCard = ({ topic, isExample = false }: TopicCardProps) => {
  const history = useHistory()

  const onClickHandler = () => {
    history.push(`/topics/${encodeURIComponent(topic.name)}`)
  }

  const handleHeading = (text: string) => {
    //regex to search for title with '/'
    const regex = /[a-zA-Z0-9]+\/[a-zA-Z0-9]+/
    const found = text.match(regex)
    if (found && found.length > 0) {
      //add space after '/' to support responsiveness
      const newText = text.replace('/', '/ ')
      return newText
    }
    return text
  }

  return (
    <div className={clsx('topic-card')}>
      <Heading mainHeading={handleHeading(topic.name)} variant="secondary" />
      <Image
        src={topic.icon ? topic.icon : exampleIcon}
        size="xs"
        variant="square"
        alt="topic logo"
      />
      <Description
        content={topic.description || ''}
        type="fullCenter"
        variant="light"
      />
      <div className="topic-card__progress">
        <TopicProgress topic={topic} size="small" />
      </div>
      <Button variant="yellow" text="open" onClick={onClickHandler} />
    </div>
  )
}

TopicCard.DisplayName = 'TopicCard'
export default TopicCard
