import { all } from 'redux-saga/effects'

import stateSagas from './state'
import authSagas from 'pages/auth/redux/sagas'
import testSagas from 'pages/test/redux/sagas'
import feedbackSagas from 'pages/feedback/redux/sagas'
import commonSagas from 'common/redux/sagas'

export default function* rootSaga() {
  yield all([
    ...stateSagas,
    ...testSagas,
    ...authSagas,
    ...feedbackSagas,
    ...commonSagas,
  ])
}
