import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  TOGGLE_TOUR,
  CommonActions,
  NotificationVariant,
  SET_SIDEBAR,
} from './types'

export function showNotification(
  message: string,
  variant: NotificationVariant
): CommonActions {
  return {
    type: SHOW_NOTIFICATION,
    payload: {
      message,
      variant,
    },
  }
}

export function hideNotification(): CommonActions {
  return {
    type: HIDE_NOTIFICATION,
  }
}

export function toggleTour(isOpen: boolean): CommonActions {
  return {
    type: TOGGLE_TOUR,
    payload: isOpen,
  }
}

export function setSidebar(open: boolean): CommonActions {
  return {
    type: SET_SIDEBAR,
    payload: open,
  }
}
