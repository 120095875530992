import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, Field } from 'formik'

import FeedbackQuestions from './components/FeedbackQuestions'
import Button from 'common/components/Button'
import Heading from 'common/components/Heading'
import Description from 'common/components/Description'
import { RootState } from 'redux/reducers'
import { sendFeedback } from './redux/actions'
import './styles.scss'
import FormikRadioBtn from 'pages/test/components/FormikRadioBtn'

const FeedbackPage = () => {
  const dispatch = useDispatch()

  const topics = useSelector((state: RootState) => {
    return state.test.test?.topics
  })

  const initialTopicsValue = topics?.map((topic) => ({
    name: topic.name,
    difficulty: '',
    relevancy: '',
  }))

  const initialValue = {
    topics: initialTopicsValue,
    testingTime: '',
    openFeedback: '',
  }

  const onSubmit = (feedback: any) => {
    dispatch(sendFeedback(feedback))
  }

  return (
    <div className="feedback">
      <div className="feedback__greetings">
        <Heading
          mainHeading="CONGRATUlATION!"
          subHeading="You have reached the finish line"
          variant="secondary"
        />
      </div>

      <div className="feedback__description">
        <Description
          content="Thank you for participating in Integrify test. We would like to ask some feedback about the test today. This would help us improve our testing system."
          type="fullCenter"
          variant="light"
        />
      </div>
      <Formik
        initialValues={initialValue}
        onSubmit={(values) => {
          onSubmit(values)
        }}
      >
        <Form>
          {topics?.map((topic, index) => {
            return (
              <FeedbackQuestions
                key={topic.name}
                topicName={topic.name}
                index={index}
              />
            )
          })}
          <div className="form-container">
            <h1 className="form-container__title">General</h1>
            <Heading
              mainHeading="How is the testing time?"
              variant="tertiary"
            />
            <div
              className="form-container__wrapper"
              role="group"
              aria-labelledby="testing-time-group"
            >
              <Field
                id="testingTime.manageable"
                htmlFor="testingTime.manageable"
                className="option__button radio"
                component={FormikRadioBtn}
                name="testingTime"
                value="Manageable"
              />
              <Field
                id="testingTime.notManageable"
                htmlFor="testingTime.notManageable"
                className="option__button radio"
                component={FormikRadioBtn}
                name="testingTime"
                value="Not manageable"
              />
            </div>
            <Heading mainHeading="Open feedback" variant="tertiary" />
            <Field
              component="textarea"
              className="test-question__script form-container__text"
              placeholder="Is there anything we could improve on the app? (Issues, bugs, etc)"
              name="openFeedback"
              cols={30}
              rows={15}
            />
          </div>
          <Button text="Send" variant="yellow" size="large" />
        </Form>
      </Formik>
    </div>
  )
}
FeedbackPage.DisplayName = 'Feedbackpage'
export default FeedbackPage
