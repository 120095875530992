import axios from 'axios'

import { AuthenticatedUser } from '../redux/types'

export default {
  async loginWithGoogle(idToken: string): Promise<AuthenticatedUser> {
    return await axios
      .post<AuthenticatedUser>('/students/google-login', {
        id_token: idToken,
      })
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async loginWithAccount(
    email: string,
    password: string
  ): Promise<AuthenticatedUser> {
    return await axios
      .post<AuthenticatedUser>('/students/login', {
        email: email,
        password: password,
      })
      .then((res) => res.data)
      .catch((error) => error.response)
  },

  async logout() {
    return await axios
      .post('/students/logout')
      .then((res) => res.data)
      .catch((error) => error.response)
  },
}
