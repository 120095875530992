import axios from 'axios'

import { Answer } from 'pages/test/redux/types'

export default {
  async startTest(testId: string, studentId: string) {
    return await axios
      .put(`tests/${testId}/students/${studentId}/start-test`)
      .then((resp) => resp.data)
  },

  async finishTest(testId: string, studentId: string) {
    return await axios
      .put(`tests/${testId}/students/${studentId}/finish-test`)
      .then((resp) => resp.data)
  },

  async fetchTest(testId: string) {
    return await axios.get(`tests/${testId}`).then((resp) => resp.data)
  },

  async fetchQuestion(questionId: string) {
    return await axios.get(`questions/${questionId}`).then((resp) => resp.data)
  },

  async submitAnswer(answer: Partial<Answer>) {
    return await axios
      .post('test-submissions', answer)
      .then((resp) => resp.data)
  },
}
