import { select, takeLatest } from 'redux-saga/effects'
import { LOG_OUT } from 'pages/auth/redux/types'

import { RootState } from 'redux/reducers'
import { SAVE_TEST_ID } from 'pages/auth/redux/types'

function* addingStateToLocalStorage(action: any) {
  if (action.type !== LOG_OUT) {
    const state: RootState = yield select()
    if (state.auth.user || action.type === SAVE_TEST_ID)
      yield localStorage.setItem('state', JSON.stringify(state))
  }
}

export default [takeLatest('*', addingStateToLocalStorage)]
